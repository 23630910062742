import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "@components/layout";
import Seo from "@components/seo";

import TopNav from "@components/topNav";
import Footer from "@components/footer";

import { IoPlay, IoPause, IoLogoPwa, IoLogoWhatsapp } from "react-icons/io5";
import { FaGooglePlay, FaFacebook } from "react-icons/fa";
import { SiKaios } from "react-icons/si";

import { InboxIcon, SparklesIcon } from "@heroicons/react/outline";

import { UserGroupIcon } from "@heroicons/react/solid";

import BoostGif from "../images/boost.gif";

import { CheckIcon } from "@heroicons/react/outline";

const posts = [
  {
    title: "GooglePlay",
    href: "#",
    category: {
      name: "GooglePlay",
      href: "#",
      color: "bg-indigo-100 text-indigo-800",
    },
    description:
      "Download the full app to use offline from the Google Play Store",
    date: "Mar 16, 2020",
    datetime: "2020-03-16",
    readingTime: "6 min",
  },
  {
    title: "KaiOS",
    href: "#",
    category: { name: "KaiOS", href: "#", color: "bg-pink-100 text-pink-800" },
    description: "Go to the KaiOS store to download Boost for feature phones",
    date: "Mar 10, 2020",
    datetime: "2020-03-10",

    readingTime: "4 min",
  },
  {
    title: "Web app",
    href: "#",
    category: {
      name: "The Boost web app",
      href: "#",
      color: "bg-green-100 text-green-800",
    },
    description: "Access all the Boost content online at boost.avert.org",
    date: "Feb 12, 2020",
    datetime: "2020-02-12",
    readingTime: "11 min",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const features = [
  {
    name: "Data light via web or WhatsApp",
    description:
      "Boost is optimised for low bandwith and requires minimal data",
  },
  {
    name: "Free to download for offline use",
    description:
      "Ornare donec rhoncus vitae nisl velit, neque, mauris dictum duis. Nibh urna non parturient.",
  },
  {
    name: "Available to individuals and organisations",
    description:
      "Etiam cras augue ornare pretium sit malesuada morbi orci, venenatis. Dictum lacus.",
  },
  {
    name: "Regularly updated with the latest information.",
    description:
      "Interdum quam pulvinar turpis tortor, egestas quis diam amet, natoque. Mauris sagittis.",
  },
];

const Boost = () => (
  <div>
    <div className="relative bg-midBlue overflow-hidden">
      <div className="max-w-6xl mx-auto">
        <div className="relative z-10 pb-8 bg-midBlue sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-midBlue transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <TopNav theme={"purpleAvert"} />

          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              {/* <h1 className="text-4xl tracking-tight font-extrabold text-yellow sm:text-5xl md:text-6xl">
                <span className="block xl:inline tracking-wide">Boost </span>{" "}
                <span className="block text-indigo-600 xl:inline"></span>
              </h1> */}
              <StaticImage
                className=""
                src="../images/boost-logo-white.svg"
                alt=""
                placeholder="TRACED_SVG"
                layout="fixed"
                width={118}
                height={61}
              />
              <p className="mt-3 text-xl text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                Giving community health workers the information and resources
                they need on HIV, sexual health and COVID-19
              </p>

              {/* <p className="mt-3 text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                Avert is using the power of digital communications to increase
                knowledge and understanding of HIV and sexual health, so people
                can make informed choices and live healthily.
              </p> */}

              <div className="mt-4 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                {/* <div className="rounded-md shadow">
                  <a
                    href="#"
                    className="w-full flex items-center justify-center px-8 py-3 text-base font-medium rounded-md text-midBlue bg-yellow hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                  >
                    Our projects
                  </a>
                </div> */}
                <div className="">
                  <button
                    href="#"
                    className="w-full flex items-center justify-center px-8 py-3 text-base text-midBlue font-medium rounded-md  bg-white hover:bg-indigo-200 md:py-4 md:text-lg md:px-10"
                  >
                    Watch video
                    <IoPlay
                      className="inline-block w-7 h-7 pl-1"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <StaticImage
          src="../images/hero-boost-2.jpg"
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          alt="Avert"
          placeholder="blurred"
          layout="fullWidth"
          width={1748}
          height={1327}
        />
      </div>
    </div>

    <div className="relative bg-white pt-6 pb-3 overflow-hidden">
      <div className="relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-purpleAvert">
                  {/* <DeviceMobileIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  /> */}
                  <StaticImage
                    src="../images/boost-icon-white.svg"
                    className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
                    alt="Avert"
                    placeholder="blurred"
                    layout="fullWidth"
                  />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-black">
                  What is Boost?
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Boost is a digital, mobile-first brand that aims to increase
                  the knowledge, skills and confidence of community health
                  workers in Southern Africa.
                </p>
              </div>
            </div>
            <div className="mt-8 border-t border-lightGrey pt-6">
              <blockquote>
                <div>
                  <p className="text-base text-gray-500">
                    &ldquo;The app has made it easy for peer educators to share
                    correct information with their clients&rdquo;
                  </p>
                </div>
                <footer className="mt-3">
                  <div className="flex items-center space-x-3">
                    <div className="flex-shrink-0">
                      <img
                        className="h-8 w-8 rounded-full"
                        src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                        alt=""
                      />
                    </div>
                    <div className="text-base font-medium text-gray-700">
                      Person's name - Africaid Zvandiri.
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              {/* <img
                className="w-full shadow-xl rounded-xl lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src="boost-screen-1.png"
                alt="Boost screens"
              /> */}
              <StaticImage
                className="w-full shadow-xl rounded-xl lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src="../images/boost-1.jpg"
                alt="Boost screens"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-purpleAvert">
                  <UserGroupIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  Purpose built for community health workers
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Boost was designed and developed in collaboration with more
                  than 100 community health workers from Malawi, Zambia and
                  Zimbabwe. Boost provides easy, up-to-date, visual and
                  interactive materials on HIV, sexual health and COVID-19 to
                  support community health workers’ ongoing learning and equip
                  them with fun, interactive tools that they can use and share
                  with their clients and communities.
                </p>
              </div>

              <div className="mt-8 border-t border-lightGrey pt-6">
                <blockquote>
                  <div>
                    <p className="text-base text-gray-500">
                      &ldquo;What I love most about Boost is that it is simple
                      but very educating & it will help me so much in my
                      field&rdquo;
                    </p>
                  </div>
                  <footer className="mt-3">
                    <div className="flex items-center space-x-3">
                      <div className="flex-shrink-0">
                        <img
                          className="h-8 w-8 rounded-full"
                          src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                          alt=""
                        />
                      </div>
                      <div className="text-base font-medium text-gray-700">
                        Loveness - Community health worker in Zambia
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              {/* <img
                className="rounded-xl shadow-xl lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                src="boost-screen-2.png"
                alt="Boost screens"
              /> */}
              <StaticImage
                className="rounded-xl shadow-xl lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                src="../images/boost-2.jpg"
                alt="Boost screens"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-4xl font-extrabold text-gray-900">Boost is:</h2>
          </div>
          <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <CheckIcon
                    className="absolute h-6 w-6 text-green-500"
                    aria-hidden="true"
                  />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                {/* <dd className="mt-2 ml-9 text-base text-gray-500">
                  {feature.description}
                </dd> */}
              </div>
            ))}
          </dl>
        </div>
      </div>

      <div className="py-3 bg-lightGrey bg-opacity-30 overflow-hidden lg:py6">
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="relative">
              <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                Features
              </h3>

              <dl className="mt-10 space-y-10">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-purpleAvert text-white">
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <dt className="text-lg leading-6 font-medium text-gray-900">
                      Card-based User Interface
                    </dt>
                    <dd className="mt-2 text-base text-gray-500">
                      Content is delivered as collections of cards, including
                      slideshows, quizzes, myth busters, and other learning
                      tools
                    </dd>
                  </div>
                </div>
              </dl>
              <dl className="mt-10 space-y-10">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-purpleAvert text-white">
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <dt className="text-lg leading-6 font-medium text-gray-900">
                      Progress tracking
                    </dt>
                    <dd className="mt-2 text-base text-gray-500">
                      Track you progress and units completed via the dashboard
                      progress rings
                    </dd>
                  </div>
                </div>

                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-purpleAvert text-white">
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <dt className="text-lg leading-6 font-medium text-gray-900">
                      Bookmarks
                    </dt>
                    <dd className="mt-2 text-base text-gray-500">
                      Save cards or collections of cards to your favourites, and
                      retrieve them later for revision
                    </dd>
                  </div>
                </div>

                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-purpleAvert text-white">
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <dt className="text-lg leading-6 font-medium text-gray-900">
                      Share with others
                    </dt>
                    <dd className="mt-2 text-base text-gray-500">
                      Share content cards or collections of cards via WhatsApp
                    </dd>
                  </div>
                </div>
              </dl>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
              {/* <img
                className="shadow-2xl relative mx-auto"
                width="320"
                src="boost.gif"
                alt=""
              /> */}
              <img
                src={BoostGif}
                width="320"
                className="shadow-2xl relative mx-auto"
                alt="Boost in action"
              />
              {/* <StaticImage
                className="shadow-2xl relative mx-auto"
                width="320"
                src="../images/boost.gif"
                alt=""
              /> */}
            </div>
          </div>

          <div className="relative mt-12 sm:mt-16 lg:mt-24">
            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="lg:col-start-2">
                <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                  BoostBot - Chatbot service
                </h3>
                <p className="mt-3 text-lg text-gray-500">
                  The WhatsApp chatbot service uses Machine Learning to answer
                  questions, and returns optimised Boost content from the same
                  system that powers the web app.
                </p>
                <p className="mt-3 text-lg text-gray-500">
                  A large number of Boost's target users have access to WhatsApp
                  as it is the number one messaging app in southern Africa.
                </p>

                <dl className="mt-10 space-y-10">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-purpleAvert text-white">
                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                          />
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="ml-4">
                      <dt className="text-lg leading-6 font-medium text-gray-900">
                        Machine Learning
                      </dt>
                      <dd className="mt-2 text-base text-gray-500">
                        The Bot learns over time by answering common questions
                        about HIV and other related health questions and
                        provides advice.
                      </dd>
                    </div>
                  </div>

                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-purpleAvert text-white">
                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="ml-4">
                      <dt className="text-lg leading-6 font-medium text-gray-900">
                        BoostBot API
                      </dt>
                      <dd className="mt-2 text-base text-gray-500">
                        The Boost API provides a simple way for BoostBot to
                        retrieve content and distribute it to different apps.
                      </dd>
                    </div>
                  </div>

                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-50 w-50">
                        {/* <img
                          className="shadow-2xl relative mx-auto"
                          width="120"
                          src="qr-code-boost.png"
                          alt=""
                        /> */}
                        <StaticImage
                          src="../images/qr-code-boost.png"
                          className="shadow-2xl relative mx-auto"
                          width="120"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="ml-4 flex items-center justify-center">
                      <dt className="text-lg leading-6 font-medium text-gray-900">
                        Scan the QR code to try it out
                      </dt>
                    </div>
                  </div>
                </dl>
              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                {/* <img
                  className="relative mx-auto"
                  width="320"
                  src="boost-bot.jpg"
                  alt=""
                /> */}
                <StaticImage
                  className="relative mx-auto"
                  width="320"
                  src="../images/boost-bot.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="bg-white mt-6">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <h2 className="max-w-md mx-auto text-3xl font-extrabold text- text-center lg:max-w-xl lg:text-left pt-3 text-fossil">
            Boost is supported by our partners
          </h2>
          <div className="flow-root self-center mt-8 lg:mt-0">
            <div className="-mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4">
              <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-4">
                <StaticImage
                  src="../images/partners/ophid.png"
                  //   className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
                  // className="h-12"
                  alt="KaiOS"
                  placeholder="blurred"
                  width={104}
                  height={58}
                />
              </div>
              <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-4">
                <StaticImage
                  src="../images/partners/africaid.png"
                  //   className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
                  // className="h-12"
                  alt="Africaid"
                  placeholder="blurred"
                  width={104}
                  height={58}
                />
              </div>
              <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-4">
                <StaticImage
                  src="../images/partners/kai-os.png"
                  //   className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
                  // className="h-12"
                  alt="KaiOS"
                  placeholder="blurred"
                  width={104}
                  height={58}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="bg-lightGrey bg-opacity-30 mt-6">
      <div className=" max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            {/* <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
                      Our Story
                    </span> */}
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Why was Boost developed?
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Community health workers support millions of people with, or at risk
            of HIV in Southern Africa. They are often people’s first point of
            contact with the health system. To play an effective role, they need
            clear, accessible, and accurate information so they can provide high
            quality care to their communities.
          </p>

          <p className="mt-8 text-xl text-gray-500 leading-8">
            Often community health workers rely on paper-based resources which
            are difficult to keep up-to-date, or in-person training which vary
            in terms of regularity and quality. There is also a lack of digital
            information resources specifically developed and tailored to the
            needs of community health workers and the Southern African context.
          </p>

          <p className="mt-8 text-xl text-gray-500 leading-8">
            With access to mobile phones growing in Southern Africa, we saw an
            opportunity to address this need by providing a digital alternative:
            a more convenient way for community health workers to stay up to
            date and have the information they need to hand throughout the day.
          </p>
        </div>
      </div>
    </div>

    <div className="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
        <div>
          <h2 className="text-center text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            How can you access Boost?
          </h2>
          {/* <p className="mt-3 text-xl text-gray-500 sm:mt-4">
            Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat
            massa dictumst amet. Sapien tortor lacus arcu.
          </p> */}
        </div>
        <div className="mt-12 grid gap-16 pt-12 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
          <div>
            <div>
              <FaGooglePlay
                // className="inline-block w-7 h-7 pl-1"
                className={classNames(
                  "bg-indigo-100 text-indigo-800 inline-block w-12 h-12 items-center p-2 rounded-lg text-sm font-medium"
                )}
                aria-hidden="true"
              />
            </div>
            <a href="#" className="block mt-4">
              <p className="text-xl font-semibold text-gray-900">
                Google Play Store
              </p>
              <p className="mt-3 text-lg">
                {" "}
                Download the full app to use offline from the Google Play Store
              </p>
            </a>
          </div>
          <div>
            <div>
              <SiKaios
                // className="inline-block w-7 h-7 pl-1"
                className={classNames(
                  "bg-pink-100 text-pink-800 inline-block w-12 h-12 items-center p-2 rounded-lg text-sm font-medium"
                )}
                aria-hidden="true"
              />
            </div>
            <a href="#" className="block mt-4">
              <p className="text-xl font-semibold text-gray-900">KaiOS</p>
              <p className="mt-3 text-lg">
                Go to the KaiOS store to download Boost for feature phones
              </p>
            </a>
          </div>
          <div>
            <div>
              <IoLogoPwa
                // className="inline-block w-7 h-7 pl-1"
                className={classNames(
                  "bg-yellow-100 text-yellow-800 inline-block w-12 h-12 items-center p-2 rounded-lg text-sm font-medium"
                )}
                aria-hidden="true"
              />
            </div>
            <a href="#" className="block mt-4">
              <p className="text-xl font-semibold text-gray-900">Web app</p>
              <p className="mt-3 text-lg">
                Access all the Boost content online at{" "}
                <a className="text-blue-800" href="https://boost.avert.org">
                  boost.avert.org
                </a>
              </p>
            </a>
          </div>
          <div>
            <div>
              <IoLogoWhatsapp
                // className="inline-block w-7 h-7 pl-1"
                className={classNames(
                  "bg-green-100 text-green-800 inline-block w-12 h-12 items-center p-2 rounded-lg text-sm font-medium"
                )}
                aria-hidden="true"
              />
            </div>
            <a href="#" className="block mt-4">
              <p className="text-xl font-semibold text-gray-900">WhatsApp</p>
              <p className="mt-3 text-lg">
                Send questions to the WhatsApp chat bot to access Boost
                information via chat
              </p>
            </a>
          </div>
          <div>
            <div>
              <FaFacebook
                // className="inline-block w-7 h-7 pl-1"
                className={classNames(
                  "bg-blue-100 text-blue-800 inline-block w-12 h-12 items-center p-2 rounded-lg text-sm font-medium"
                )}
                aria-hidden="true"
              />
            </div>
            <a href="#" className="block mt-4">
              <p className="text-xl font-semibold text-gray-900">Facebook</p>
              <p className="mt-3 text-lg">
                Join the Boost Facebook group to discuss content further and
                talk to other CHWs.
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>

    {/* <div id="features" className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div>
            <span className="mt-1 block text-5xl tracking-tight font-extrabold sm:text-2xl xl:text-4xl pb-2">
              <span className=" text-gray-900">How can you access boost </span>
            </span>

            <p className="mt-3 max-w-3xl text-xl text-gray-500">
              Since its launch the product has been evolving with new content
              and features. A WhatsApp chatbot, KaiIOS and native app verison
              are now available.
            </p>
          </div>
          <div className="mt-8 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <StaticImage alt="WhatsApp" src="../images/whatsapp.svg" />
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <StaticImage
                alt="KaiOS"
                className="max-h-12"
                src="../images/kaios.svg"
              />
            </div>

            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <StaticImage
                className="max-h-12"
                src="../images/googleplay.svg"
                alt="Google Play"
              />
            </div>
          </div>
        </div>
      </div>
    </div> */}

    <div className="bg-lightGrey bg-opacity-30 pt-12 sm:pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Trusted by comunity health workers worldwide
          </h2>
          <p className="mt-3 text-xl text-fossil sm:mt-4">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
            Repellendus repellat laudantium.
          </p>
        </div>
      </div>
      <div className="mt-10 pb-12 bg-white sm:pb-16">
        <div className="relative">
          <div className="absolute inset-0 h-1/2 bg-lightGrey bg-opacity-30" />
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto">
              <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                <div className="flex flex-col border-b border-disabled p-6 text-center sm:border-0 sm:border-r">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-fossil">
                    Boost users
                  </dt>
                  <dd className="order-1 text-5xl font-extrabold text-midBlue">
                    10,000
                  </dd>
                </div>
                <div className="flex flex-col border-t border-b border-disabled p-6 text-center sm:border-0 sm:border-l sm:border-r">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-fossil">
                    Questions asked
                  </dt>
                  <dd className="order-1 text-5xl font-extrabold text-midBlue">
                    2M
                  </dd>
                </div>
                <div className="flex flex-col border-t border-disabled p-6 text-center sm:border-0 sm:border-l">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-fossil">
                    Downloads a month
                  </dt>
                  <dd className="order-1 text-5xl font-extrabold text-midBlue">
                    100k
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* Partners */}
    {/* <div>
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <p className="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
          Working in partnership with
        </p>
        <div className="mt-6 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <StaticImage
              src="../images/partners/vso.png"
              alt="VSO"
              placeholder="blurred"
              width={104}
              height={58}
            />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <StaticImage
              src="../images/partners/africaid.png"
              alt="Africaid"
              placeholder="blurred"
              width={104}
              height={58}
            />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <StaticImage
              src="../images/partners/ophid.png"
              alt="KaiOS"
              placeholder="blurred"
              width={104}
              height={58}
            />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <StaticImage
              src="../images/partners/mpt.png"
              //   className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
              // className="h-12"
              alt="MPT"
              placeholder="blurred"
              width={104}
              height={58}
            />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <StaticImage
              src="../images/partners/kai-os.png"
              alt="KaiOS"
              placeholder="blurred"
              width={104}
              height={58}
            />
          </div>
        </div>
      </div>
    </div> */}

    <Footer />
  </div>
);

export default Boost;
